import { reactive} from "vue";

export default {     
    install(app, options) {
        app.config.globalProperties.$payment = {
            info: reactive({
                data:{
                    gateway: {},
                    plan: {},
                    usual_plan: {},
                    trial_plan: {}    
                }
            }),
            update(currency){
                axios.get('/payments/get_info/' + currency)
                .then(resp => {
                    this.info.data = resp.data.data;
                })
                .catch(err => {

                });
            },
            getAmount(){
                return this.info.data.plan.amount ? this.info.data.plan.amount/100 : 0;
            },
            getUsualAmount(){ 
                return this.info.data.usual_plan.amount ? this.info.data.usual_plan.amount/100 : 0;
            },
            getTrialAmount(){ 
                return this.info.data.trial_plan.amount ? this.info.data.trial_plan.amount/100 : 0;
            },
            getDuration(){
                return this.info.data.plan.plan_type ? this.info.data.plan.plan_type.duration : 0;
            },
            getUsualDuration(){
                return this.info.data.usual_plan.plan_type ? this.info.data.usual_plan.plan_type.duration : 0;
            },
            getTrialDuration(){
                return this.info.data.trial_plan.plan_type ? this.info.data.trial_plan.plan_type.duration : 0;
            },
            getUsualType(){
                return this.info.data.usual_plan ? this.info.data.usual_plan.type : '';
            },
            failPayment(){
                let message = app.config.globalProperties.$translate('pay_failed');
                app.config.globalProperties.$modals.notification(message);
                setTimeout(() => {
                    location = "/dashboard";
                }, 2000);
            },
            successPayment(){
                location = '/successfulPayment';
            }
            
        };
        
        app.config.globalProperties.$payment.update(app.config.globalProperties.$store.currency);
        
    }
}
