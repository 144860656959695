<template>
  <a class="navbar-brand" href="/">
       <div class="logo">
            <img class="logo" src="/img/front/logo-map.png" :alt="$config('app_name')">
            {{$config('app_name')}}
       </div>
    <!--<img class="logo" src="/img/front/logo.svg" :alt="$t('cookie_table_GeoSaturl')">-->
  </a>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style scoped>
.logo {
  /*width: 234px;*/
    font-size: 24px;
    color: #524623;
    text-transform: uppercase;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.logo img{
    margin-right: 5px;
}

@media all and (max-width: 767px) {
    .logo {
   /* width: 188px;*/
      font-size: 20px;
    }
    .logo img{
        width: 28px;
    }
}
</style>
